<template>
  <div class="index">
    <div ref="header">
      <Header :bg="bg"></Header>
    </div>
    <div class="toubu">
      <div class="text">
        解决用户差异化需求
        <div class="bottom">
          拥有一支高、精、尖的实施和运维服务团队，为您排忧解难
        </div>
      </div>
    </div>
    <!-- <div class="bar" ref="test">
      <div
        class="son"
        v-for="(item, index) in list"
        :key="index"
        @click="active(item)"
        :class="item.type == type ? 'active' : ''"
      >
        {{ item.name }}
        <em></em>
      </div>
    </div> -->
    <!-- <Technology v-show="type == 1"></Technology>
    <Case v-show="type == 2"></Case>
    <Investment v-show="type == 3"></Investment>
    <Serve v-show="type == 4"></Serve> -->

    <div class="newbox">
      <div class="bg">
        <div class="yi">
          2000+
          <span> 项目用户 </span>
        </div>
        <div class="er">
          23年专注: <br />
          中国领先的智慧城市解决方案与技术服务提供商
        </div>
        <div class="san">
          <div class="son" v-for="(item, index) in list" :key="index">
            <img :src="item.src" alt="" class="img" />
            <div class="num">
              <!-- {{ item.num }} -->
              <ICountUp :endVal="item.num" :options="options" ref="xx" />
              <div class="danwei">{{ item.danwei }}</div>
            </div>

            <div class="text">{{ item.text }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="jishu">
      <div class="left">
        <div class="biaoti">产品、技术与服务</div>
        <div class="jj">100+专家为汉康客户提供售前、售后技术服务</div>
        <div class="boxx">
          <div
            class="son"
            v-for="(item, index) in fuwu"
            :key="index"
            :class="dangqian == item.type ? 'active' : ''"
            @click="check(item)"
          >
            <img class="img" :src="item.icon" alt="" />
            <div class="name">{{ item.name }}</div>
            <div class="line"></div>
          </div>
        </div>
      </div>
      <div class="right">
        <el-carousel
          direction="vertical"
          indicator-position="none"
          class="fuwu-lunbo"
          ref="carousel"
          @change="change"
        >
          <el-carousel-item v-for="(item, index) in fuwu" :key="index + 1000">
            <div class="bo">
              <img class="img" :src="item.src" alt="" />
              <div class="miaoshu">{{ item.miaoshu }}</div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>

    <div class="lanshe">
      <div class="top">汉康专业技术团队为您提供技术服务</div>
      <div class="bottom">
        具有经验丰富的专家，提供专属的运维保障、咨询建议、企业赋能等服务
      </div>
    </div>

    <div class="zhichi">
      <div class="bt">服务支持</div>
      <div class="zhichi-box">
        <div class="son" v-for="(item, index) in zhichi" :key="index">
          <img class="zhichi-img" :src="item.src" alt="" />
          <div class="zhichi-miaoshu">
            <div class="title">{{ item.title }}</div>
            <div class="text">{{ item.text }}</div>
            <div class="lianxi">
              <div class="val" v-for="(v, i) in item.biaoqian" :key="i">
                {{ v.val }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>

    <div class="toback" ref="toback">
      <div class="toback-lianxi">
        <div class="toback-ph">
          <div class="toback-sons">
            <div class="toback-barss">
              <div class="toback-top">咨询电话</div>
              <div class="toback-bottom">133-3098-1239</div>
            </div>
            <div class="toback-barss">
              <div class="toback-top">技术支持</div>
              <div class="toback-bottom">189-8005-3442</div>
            </div>
            <div class="toback-barss">
              <div class="toback-top">售后电话</div>
              <div class="toback-bottom">400-8878-352</div>
            </div>
          </div>
        </div>
        <div class="toback-wx">
          <div class="toback-son">
            <img src="@/assets/imgs/home/gongzonghao.png" alt />
            <div class="toback-tip">(成都汉康微信)</div>
          </div>
        </div>
      </div>
      <div class="toback-up" @click="up"></div>
    </div>
  </div>
</template>

<script>
import ICountUp from "vue-countup-v2";
import Header from "../../components/Header.vue";
import Footer from "../../components/Footer.vue";
// import Technology from "./components/Technology.vue";
// import Case from "./components/Case.vue";
// import Investment from "./components/Investment.vue";
// import Serve from "./components/Serve.vue";
export default {
  components: {
    Header,
    Footer,
    ICountUp,
    // Technology,
    // Case,
    // Investment,
    // Serve,
  },
  data() {
    return {
      list: [
        {
          src: require("../../assets/imgs/home/bottomicon (2).png"),
          num: 2000,
          danwei: "+",
          text: "服务数量",
        },
        {
          src: require("../../assets/imgs/home/bottomicon (3).png"),
          num: 23,
          danwei: "年",
          text: "公司成立",
        },
        {
          src: require("../../assets/imgs/home/bottomicon (4).png"),
          num: 200,
          danwei: "套",
          text: "相关产品",
        },
        {
          src: require("../../assets/imgs/home/bottomicon (1).png"),
          num: 90,
          danwei: "%",
          text: "分布区域",
        },
      ],
      type: 1,
      arr: [],
      timer: null,
      bool: true,
      options: {
        useEasing: true,
        useGrouping: true,
        separator: "", //分割 符号
        decimal: ".",
        prefix: "",
        suffix: "",
        duration: 2.5,
      },
      fuwu: [
        {
          name: "服务体系",
          src: require("../../assets/imgs/fuwu/1.png"),
          icon: require("../../assets/imgs/fuwu/fuwuicon (1).png"),
          miaoshu:
            "通过了ISO9000系列质量管理标准体系为客户提供标准化、科学化服务",
          type: 0,
        },
        {
          name: "集成服务",
          src: require("../../assets/imgs/fuwu/2.png"),
          icon: require("../../assets/imgs/fuwu/fuwuicon (2).png"),
          miaoshu:
            "20+系统集成技术积累专家团队，为客户提供前期咨询、方案设计、软件开发、应用服务、系统集成等专业化的集成服务",
          type: 1,
        },
        {
          name: "运营服务",
          src: require("../../assets/imgs/fuwu/3.png"),
          icon: require("../../assets/imgs/fuwu/fuwuicon (3).png"),
          miaoshu:
            "通过了信息技术服务运维标准ITSS认证，为智慧城市投资建设项目，提供专业的运营服务",
          type: 2,
        },
        {
          name: "运维保障",
          src: require("../../assets/imgs/fuwu/4.png"),
          icon: require("../../assets/imgs/fuwu/fuwuicon (4).png"),
          miaoshu:
            "专业的施工技术人员负责安装或现场指导；专业的技术人员到场调试，严格调试规程；并设7*24小时售后服务热线",
          type: 3,
        },
      ],
      dangqian: 0,
      zhichi: [
        {
          src: require("../../assets/imgs/fuwu/zhichi (2).png"),
          title: "产品技术服务",
          text: "汉康高级技术专家，结合丰富一线经验，快速定位问题并提供最佳解决方案",
          biaoqian: [{ val: "服务热线：02886660094" }],
        },
        {
          src: require("../../assets/imgs/fuwu/zhichi (3).png"),
          title: "售前服务  ",
          text: "由专属1V1客户经理作为服务对接人，为客户提供更专业更全面，更主动的技术服务",
          biaoqian: [
            { val: "全天候 7*24小时" },
            { val: "服务热线：02886660094" },
          ],
        },
        {
          src: require("../../assets/imgs/fuwu/zhichi (1).png"),
          title: "售后服务",
          text: "向客户提供专业的技术支撑和保障能力，协助客户处理关键业务问题，保障产品正常使用",
          biaoqian: [{ val: "服务热线：02886660094" }],
        },
      ],
      bg: null,
    };
  },
  methods: {
    active(val) {
      this.type = val.type;
    },

    up() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      let sudu = 40;
      if (top >= 0 && top < 1000) {
        sudu = 40;
      } else if (top >= 1000 && top < 2000) {
        sudu = 60;
      } else if (top >= 2000 && top < 3000) {
        sudu = 100;
      } else if (top >= 3000 && top < 4000) {
        sudu = 140;
      } else {
        sudu = 200;
      }
      this.timer = setInterval(() => {
        document.body.scrollTop =
          document.documentElement.scrollTop =
          top -=
            sudu;

        if (top <= 0) {
          clearInterval(this.timer);
        }
      }, 10);

      // document.body.scrollTop = 0;
    },
    scroll() {
      let scroll =
        document.body.scrollTop || document.documentElement.scrollTop;
      this.arr.push(scroll);
      //console.log("scroll :>> ", scroll);
      if (this.arr.length > 2) {
        let a = [this.arr[this.arr.length - 1]];
        let b = [this.arr[this.arr.length - 2]];

        if (a - b >= 0) {
          // console.log("往下滑");
          this.$refs.header.style.position = "absolute";
          this.$refs.header.style.top = "0";
          this.$refs.header.style.left = "0";
          this.$refs.header.style.zIndex = "100";

          if (scroll >= 470) {
            this.show = true;
          } else {
            this.show = false;
          }
        } else {
          // console.log("往上滑");
          if (scroll > 300) {
            this.$refs.header.style.position = "fixed";
            this.$refs.header.style.top = "0";
            this.$refs.header.style.left = "0";
            this.$refs.header.style.zIndex = "100";
            this.bg = "black";
          }
        }
        if (scroll > 120) {
          this.$refs.toback.style.opacity = "1";
        } else {
          this.$refs.toback.style.opacity = "0";
        }
      }
      if (scroll <= 280) {
        this.$refs.header.style.position = "absolute";
        this.$refs.header.style.top = "0";
        this.$refs.header.style.left = "0";
        this.$refs.header.style.zIndex = "100";
      }
    },
    check(item) {
      this.dangqian = item.type;
      this.$refs.carousel.setActiveItem(item.type);
    },
    change(val) {
      this.dangqian = val;
    },
  },
  mounted() {
    document.body.scrollTop = 0;

    window.addEventListener("scroll", this.scroll, true);
  },
  beforeDestroy() {
    // this.time=null
    window.removeEventListener("scroll", this.scroll, true);
  },
};
</script>

<style lang="scss" scoped>
.active {
  color: rgba(68, 112, 255, 1) !important;
  font-weight: 700;

  em {
    display: block !important;
  }
}

.index {
  width: 100%;
  height: 100%;
  background-color: #fff;

  .toubu {
    width: 1920px;
    height: 500px;
    background-image: url("../../assets/imgs/fuwu/bg.png");
    background-size: 100% 100%;
    margin: 0 auto;
    position: relative;

    .text {
      // min-width: 462px;
      position: absolute;
      top: 180px;
      left: 343px;
      font-size: 60px;
      font-family: Alibaba PuHuiTi;
      font-weight: 700;
      line-height: 84px;
      color: #ffffff;

      // border-bottom: 1px solid rgba(75, 75, 75, 1);
      // padding-bottom: 20px;
      .bottom {
        font-size: 20px;
        font-family: Alibaba PuHuiTi Xi !important;
        font-weight: normal;
        line-height: 22px;
        color: #ffffff;
        margin-top: 8px;
      }
    }
  }

  .bar {
    width: 1920px;
    height: 84px;
    margin: 0 auto;
    background-color: rgb(245, 248, 255);
    padding: 0 260px;
    font-size: 18px;
    display: flex;

    .son {
      height: 100%;
      width: 180px;
      display: flex;
      align-items: center;
      color: rgba(162, 162, 162, 1);
      position: relative;
      cursor: pointer;

      em {
        width: 50px;
        height: 2px;
        background-color: rgba(68, 112, 255, 1);
        position: absolute;
        bottom: 0;
        left: 0;
        display: none;
      }
    }

    .son:hover {
      color: rgba(68, 112, 255, 1);
      font-weight: 700;
    }

    .son:hover em {
      display: block;
    }
  }

  .newbox {
    width: 100%;
    padding: 60px 260px;

    .bg {
      width: 100%;
      height: 456px;
      background: url("../../assets/imgs/fuwu/numbg.png");
      background-size: 100% 100%;
      position: relative;

      .yi {
        color: rgba(51, 51, 51, 1);
        font-size: 46px;
        font-family: Alibaba PuHuiTi;
        font-weight: 700;
        position: absolute;
        top: 58px;
        left: 46px;

        span {
          font-size: 20px;
          font-weight: normal;
          color: #333333;
          font-family: Alibaba PuHuiTi Xi !important;
        }
      }

      .er {
        // width: 380px;
        font-size: 26px;
        font-family: Alibaba PuHuiTi;
        font-weight: 600;
        line-height: 35px;
        color: #333333;
        position: absolute;
        top: 138px;
        left: 46px;
      }

      .san {
        width: 100%;
        height: 126px;
        padding: 0 46px;
        position: absolute;
        top: 270px;
        left: 0;
        display: flex;
        justify-content: space-between;

        .son {
          width: 310px;
          height: 134px;
          // background-color: rgb(22, 26, 34);
          background-color: rgba(255, 255, 255, 0.4);
          backdrop-filter: blur(4px);
          border-radius: 10px;
          position: relative;

          .img {
            width: 84px;
            height: 84px;
            position: absolute;
            top: 20px;
            left: 20px;
          }

          .num {
            position: absolute;
            font-size: 56px;
            font-family: Alibaba PuHuiTi;
            font-weight: bold;
            color: #333333;
            top: 20px;
            left: 120px;
            font-size: 60px;
            font-weight: 700;

            .danwei {
              font-size: 14px;
              position: absolute;
              right: -20px;
              top: 8px;
              font-size: 13px;
              font-family: Alibaba PuHuiTi;
              font-weight: 400;
              color: #333333;
            }
          }

          .text {
            font-size: 16px;
            color: rgba(136, 136, 136, 1);
            position: absolute;
            font-family: Alibaba PuHuiTi Xi !important;
            left: 120px;
            bottom: 24px;
          }
        }
      }
    }
  }

  .jishu {
    width: 100%;
    height: 610px;
    display: flex;

    .left {
      width: 850px;
      height: 100%;
      background: url("../../assets/imgs/fuwu/chanping.png");
      background-size: 100% 100%;
      padding-top: 60px;

      .biaoti {
        width: 100%;
        padding-left: 260px;
        font-size: 36px;
        font-family: Alibaba PuHuiTi Xi !important;
        font-weight: normal;
        line-height: 24px;
        color: #ffffff;
      }

      .jj {
        width: 100%;
        padding-left: 260px;
        margin-top: 20px;
        font-size: 16px;
        font-family: Alibaba PuHuiTi Xi !important;
        font-weight: normal;
        line-height: 22px;
        color: #c9c9c9;
      }

      .boxx {
        margin-top: 34px;
        width: 100%;

        .son {
          padding-left: 260px;
          width: 100%;
          height: 96px;
          display: flex;
          align-items: center;

          // border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          position: relative;
          cursor: pointer;

          .img {
            width: 38px;
            height: 38px;
            // background-color: #fff;
          }

          .name {
            font-size: 22px;
            font-family: Alibaba PuHuiTi Xi !important;
            font-weight: normal;
            line-height: 24px;
            color: #ffffff;
            margin-left: 20px;
          }

          .line {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 590px;
            height: 1px;
            background-color: rgba(255, 255, 255, 0.1);
          }
        }

        .active {
          background: linear-gradient(
            90deg,
            rgba(42, 50, 67, 0.13) 0%,
            #2a3243 100%
          );
        }
      }

      .son:last-of-type .line {
        display: none;
      }
    }

    .right {
      flex: 1;
      // background: url("../../assets/imgs/fuwu/tixi.png");
      // background-size: 100% 100%;
    }
  }

  .lanshe {
    width: 1920px;
    height: 160px;
    background: rgba(13, 82, 186, 1);
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .top {
      font-size: 28px;
      font-family: Alibaba PuHuiTi Xi !important;
      font-weight: normal;
      line-height: 24px;
      color: #ffffff;
    }

    .bottom {
      margin-top: 30px;
      font-size: 16px;
      font-family: Alibaba PuHuiTi Xi !important;
      font-weight: normal;
      line-height: 22px;
      color: #ffffff;
    }
  }

  .zhichi {
    width: 100%;
    padding: 60px 250px;

    .bt {
      width: 100%;
      text-align: center;
      font-size: 36px;
      font-family: Alibaba PuHuiTi Xi !important;
      font-weight: normal;
      line-height: 24px;
      color: #333333;
    }

    .zhichi-box {
      width: 100%;
      height: 460px;
      display: flex;
      justify-content: space-between;
      margin-top: 60px;

      .son {
        width: 460px;
        height: 460px;
        background-color: rgba(247, 248, 250, 1);

        .zhichi-img {
          width: 100%;
          height: 280px;
        }

        .zhichi-miaoshu {
          width: 100%;
          height: 180px;
          padding: 26px 30px;

          .title {
            font-size: 22px;
            font-family: Alibaba PuHuiTi Xi !important;
            font-weight: normal;
            line-height: 30px;
            color: #333333;
          }

          .text {
            font-size: 16px;
            font-family: Alibaba PuHuiTi Xi !important;
            font-weight: normal;
            line-height: 22px;
            color: #888888;
            margin-top: 16px;
          }

          .lianxi {
            width: 100%;
            margin-top: 20px;
            display: flex;
            font-size: 16px;
            font-family: Alibaba PuHuiTi Xi !important;
            font-weight: normal;
            line-height: 22px;
            color: #888888;

            .val {
              margin-right: 40px;
            }
          }
        }
      }

      .son:hover {
        box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.16);
      }
    }
  }
}
</style>
